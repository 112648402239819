.preview-image {
  cursor: pointer;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.auto-complete {
  .dropdown-menu {
    padding: 0px;
    background-color: white;
    z-index: 1;

    .drop-down-menu {
      overflow-y: scroll;
      border: 1px solid #ced4da;
      border-radius: 3px;

      .drop-down-option-item {
        padding: 9px 20px;
        cursor: pointer;

        &:hover {
          background-color: #556ee630;
        }

        &.selected {
          background-color: #556ee6;
          color: white;
        }
      }

      .no-option-text {
        padding: 9px 20px;
        color: rgba(0, 0, 0, 0.5);
        text-align: center;
        font-weight: 700;
      }
    }
  }
  .drop-down-card {
    width: 100%;
    margin-bottom: 0px;

    .drop-down-card-body {
      padding: 8px 0px;
    }
  }
}

